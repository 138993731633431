import { render, staticRenderFns } from "./Valença.vue?vue&type=template&id=46a6c97e&scoped=true&"
import script from "./Valença.vue?vue&type=script&lang=js&"
export * from "./Valença.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46a6c97e",
  null
  
)

export default component.exports